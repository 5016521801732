import * as React from "react"
import { SVGProps } from "react"

const CalendarSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.333 2.917V1.25m3.334 1.667V1.25m-3.75 3.333h4.166m-5 4.167h5.834a.833.833 0 0 0 .833-.833v-5a.833.833 0 0 0-.833-.834H2.083a.833.833 0 0 0-.833.834v5a.833.833 0 0 0 .833.833Z"
      stroke="currentColor"
      strokeWidth={0.7}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CalendarSmallIcon
