import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { SimilarPost } from '../../../pages/article'
import { DEFAULT_IMAGE } from '../../../utils/Constant'
import { prepareContentShort, renderDate } from '../../../utils/Helper'
import articleClasses from './ArticleListSection.module.css'
import CalendarSmallIcon from '../../icons/CalendarSmallIcon'
import { useInView } from 'react-intersection-observer'

interface ArticleListSectionProps {
    isMobile: boolean
    posts: SimilarPost[]
    projectName?: string
}

const ArticleListSection = ({ posts, isMobile, projectName }: ArticleListSectionProps) => {
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    })

    const renderCard = (data: any, small?: boolean) => {
        let articleURL = data ? `/article/${data.category}/${data.slug}` : '';

        return (
            data ?
                <div className={`${small && 'flex'} mb-5`}>
                    <a href={articleURL} className={`block ${small && 'w-1/2 pr-2'}`}>
                        <img
                            title={`Properti impian di ${projectName || ''}, Ecatalog Sinar Mas Land`}
                            alt={`Properti impian di ${projectName || ''}, Ecatalog Sinar Mas Land`}
                            src={data.image_thumbnail_url || DEFAULT_IMAGE}
                            className='mb-2 rounded'
                            loading='lazy'
                            style={{
                                width: small ? '100%' : 522,
                                height: small ? '100%' : 334
                            }} />
                    </a>
                    <div className={`${small && 'w-1/2 pl-2'}`}>
                        <div className='flex items-center mb-2'>
                            <CalendarSmallIcon className='text-xs mr-1 text-gray-#8F8F8F' />
                            <span className='text-gray-#8F8F8F text-xs'>{renderDate(data.created_at, true, false)}</span>
                        </div>
                        <a href={articleURL} className='two-line text-lg font-bold text-black-#353535 mb-11px leading-snug hover:text-main transition-colors duration-150 ease-linear'>
                            {data.title}
                        </a>
                        <p className='three-line text-xs text-black-#353535 mb-5 leading-snug'>
                            {prepareContentShort(data.content_short)}...
                        </p>
                        <a href={articleURL} className='text-xs font-bold text-main underline hover:text-main-dark transition-colors duration-150 ease-linear'>
                            Read More
                        </a>
                    </div>
                </div>
                : null
        )
    }

    return (
        <div className="w-full py-10 container">
            <div ref={ref} className='md:flex justify-between items-center mb-5'>
                <div className=''>
                    <h2 className='text-black-#3D3D3D text-lg lg:text-32px font-bold'>Articles</h2>
                    <p className='mb-3 md:mb-0 text-xs text-xs lg:text-base text-gray-#8F8F8F'>Find out the latest information from eCatalog Sinar Mas Land</p>
                </div>
                <a href="/article">
                    <button className="px-5 py-2 bg-red-#FFEEEF hover:bg-main text-red-#E31E26 hover:text-white active:bg-main rounded outline-none focus:outline-none transition-colors duration-150 ease-linear flex items-center justify-center">
                        <span className='text-sm font-semibold mr-3'>View all articles</span>
                        <FontAwesomeIcon icon={faLongArrowAltRight} width="0" />
                    </button>
                </a>
            </div>
            {
                inView &&
                <div className="md:flex md:flex-row">
                    <div className='w-full md:w-1/2'>
                        {renderCard(posts[0])}
                    </div>
                    <div className='w-full md:w-1/2'>
                        {posts[1] && renderCard(posts[1], true)}
                        {posts[2] && renderCard(posts[2], true)}
                        {posts[3] && renderCard(posts[3], true)}
                    </div>
                </div>
            }
        </div>
    )
}

export default ArticleListSection
